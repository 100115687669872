body {
  margin: 0;
  padding: 0;
  font-family: peachy-keen-jf,sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

div {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #9a2301;
  text-decoration: underline;
}

h1 {
  display: inline-block;
  width: 70%;
  max-width: 600px;
  font-size: 3rem;
  font-weight: normal;
}

h2 {
    font-weight: normal;
    font-size: 2.5rem;
    margin-bottom: 0;
    line-height: 1;
    text-align: left;
}

.palookaville, .red {
  color: #9a2301;
}

.brooklyn, .green {
  color: #00994c;
}

.farre {
  max-width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .page-content {
    .desc {
      max-width: 80%;
    }
  }
}

// ----------------------------------------------------------------------------------------------------
// -------------------------------------------------- STARTSIDAN --------------------------------------
// ----------------------------------------------------------------------------------------------------

.startsida {
  box-sizing: border-box;

  .festgeneral {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
    width: 100vw;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
    }

    .bild, .details {
      color: white;
      max-width: 80%;
      margin: {
        left: auto;
        right: auto;
        top: 30px;
      }
    }

    .bild {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.9);
      img {
        border: 3px solid white;
      }
      transform: rotate(-5deg);
    }
  }

  .skalank {
    height: 110px;
    display: block;
    margin: 0 auto;
  }

  .banner {
    .chequered {
      background-image: url('./images/chequered.svg');
      background-repeat: repeat-x;
      background-color: black;
      height: 100px;
    }

    .savethedate {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 2.3rem;
    }

    .partyinfo {
      display: flex;
      flex-wrap: wrap;
      font-size: 2.5rem;
      .age, .logo, .come {
        width: 100%;
      }
      .logo {
        order: 0;
        position: relative;
        top: -40px;
        img {
            width: 50%;
          max-width: 300px;
        }
      }
      .age {
        order: 1;
      }
      .come {
        order: 2;
      }
    }
  }

  .desc {
    font-size: 2rem;
    a {
      border: 0;
    }
  }

  .fullpott {
    font-size: 2.5rem;
  }

  .button {
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 335px;
  }
}

// ----------------------------------------------------------------------------------------------------
// -------------------------------------------------- HEADER NAV SUBPAGES -----------------------------
// ----------------------------------------------------------------------------------------------------
.header-nav {
  position: relative;
  height: 250px;
  .background {
    background-image: url('./images/chequered.svg');
    background-repeat: repeat-x;
    width: 100%;
    height: 100px;
  }

  .till-startsidan {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 20px;
    left: 20px;

    a {
      border: 0;
      display: block;
    }
  }
}

// ----------------------------------------------------------------------------------------------------
// -------------------------------------------------- FOOTER ------------------------------------------
// ----------------------------------------------------------------------------------------------------
.footer {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
  .logo {
    width: 30%;
    transition: all 0.2s;
    display: inline-block;
    a {
      border: 0;
    }
    &:hover {
      transform: rotate(30deg);
    }
  }
}

// ----------------------------------------------------------------------------------------------------
// -------------------------------------------------- HITTA -------------------------------------------
// ----------------------------------------------------------------------------------------------------
.hitta, .anmalan, .presenter {
  .navlinks {
    a {
      display: inline-block;
      padding: 10px;
      font-size: 2rem;
      border: 3px solid black;
      margin: 10px;
      transition: all 0.2s;
      &:hover {
        color: #9a2301;
      }
    }
  }
  .map {
    width: 100%;
    margin: 20px auto;
    a {
      border: 0;
    }
  }

  .googlemapslink {
    display: block;
    margin-bottom: 30px;
  }
}

.anmalan {
  font-size: 1.5rem;

  .desc {
    text-align: left;
  }

  .antalAnmalda {
    font-weight: bold;
    fill: red;
  }

  ul {
    padding: 0;
    list-style: none;
    text-align: left;
    display: inline-block;
    margin: 0 auto;
    li {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        padding-right: 10px;
      }

      img {
        width: 15px;
      }
    }
  }

  .desc, .signup, .framfora {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  input {
    border: 1px solid black;
    padding: 10px;
    font-family: peachy-keen-jf,sans-serif;
    text-transform: uppercase;
  }

    input[type='submit'] {
        font-size: 1.5rem;
      background-color: white;
    }

  .signup {
    text-align: left;
    max-width: 80%;
    margin: 0 auto;

    div {
      margin-bottom: 20px;
    }

    span {
      width: 100%;
      display: block;
    }
    label {
      width: 100%;
    }
    input {
      width: 100%;
      box-sizing: border-box;
    }

    $checkboxSize: 50px;

    .framforaCheckbox {
      position: relative;

      input:checked ~ .checkbox {
        outline: 1px solid transparent;
        &:after {
          opacity: 1;
        }
      }

      input {
        width: $checkboxSize - 3px;
        height: $checkboxSize - 3px;
      }
    }

    .checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: $checkboxSize;
      width: $checkboxSize;
      background-color: white;
      outline: 1px solid black;
      pointer-events: none;
      opacity: 1;
      overflow: hidden;
      transition: all 0.5s;
      &:after {
        transition: all 0.5s;
        display: block;
        opacity: 0;
        position: absolute;
        content: " ";
        background: transparent url('images/onair.png') no-repeat;
        background-size: contain;
        width: $checkboxSize;
        height: $checkboxSize;
      }
    }
  }

  .skasnubbe {
    transform-origin: center;
  }

  li span:not(.skasnubbe) {
    padding-left: 10px;
    display: inline-block;
  }

  .framfora {
    text-align: left;
    font-size: 1rem;
    max-width: 80%;
    margin: 30px auto;
  }

  .ticker {
    max-width: 300px;
  }
}

.presenter {
    .desc {
        font-size: 1.5rem;
        margin: 80px auto;
        text-align: left;
    }
    .swish {
        margin: 20px auto;
        max-width: 400px;
    }
}

.skamusik {
  .desc {
    font-size: 1.2rem;
    text-align: left;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }

  .tavlor {
    width: 90%;
    margin: 0 auto;
    img {
      max-width: 90%;
      display: inline-block;
      margin: 20px auto;
    }
    img:nth-of-type(1) {
      transform: rotate(0.7deg);
    }

    img:nth-of-type(2) {
      transform: rotate(-0.6deg);
    }
  }

  .videoplayer {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.5s;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }

    .close {
      position: absolute;
      top: -40px;
      right: -40px;
      width: 70px;
      cursor: pointer;
      transition: all 0.3s;
      transform-origin: center;
      img {
        display: block;
      }
      &:hover {
        transform: rotate(90deg);
      }
    }

    .video {
      width: 90%;
      max-width: 560px;
      outline: 10px solid white;
      height: 315px;
      background-color: rgb(0, 0, 0);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-1deg);
      position: absolute;
      box-shadow: 10px 10px 20px rgb(0, 0, 0);
    }
  }

  .musikexempel {
    position: relative;

    .musiclink {
      background-color: rgba(255, 0, 255, 0);
      position: absolute;
      width: 100px;
      height: 30px;
      cursor: pointer;
      transform: rotate(0.7deg);

      &.madness {
        top: 73px;
        left: 25px;
        height: 24px;
      }

      &.specials {
        top: 100px;
        left: 24px;
        width: 120px;
      }

      &.beat {
        top: 132px;
        left: 23px;
      }

      &.manners {
        top: 164px;
        left: 23px;
        width: 120px;
      }
      &.buster {
        top: 196px;
        left: 23px;
        width: 120px;
      }
    }
  }
}

@import "desktop";
