@media screen and (min-width: 1024px) {
    .page-content {
        position: relative;
        top: -100px;
    }
    .footer {
        width: 400px;
        margin-left: auto;
    }

    .startsida {
        .skalank {
            height: 60px;
            position: relative;
            top: 20px;
            display: inline-block;
        }
        .navigation {
            max-width: 80%;
            margin: {
                left: auto;
                right: auto;
                top: 200px;
            }
            display: flex;
            justify-content: space-evenly;
            .button {
                width: 30%;
            }
        }
        .desc {
            max-width: 1200px;
            margin: 30px auto;
            font-size: 2rem;
        }

        .banner {
            .chequered {
                height: 200px;
            }
        }

        .banner .partyinfo {
            position: absolute;
            width: 100%;
            max-width: 1600px;
            left: 50%;
            transform: translateX(-50%);
            top: 140px;
            font-size: 3.5rem;
            justify-content: space-around;
            .age, .logo, .come {
                width: 32%;
                align-self: center;
            }
            .come {
                text-align: left;
            }
            .age {
                order: 0;
                text-align: left;
            }
            .logo {
                order: 1;
                position: static;
            }
        }

        .banner .savethedate {
            margin-top: 250px;
            font-size: 3rem;
        }
    }

    .anmalan {
        .ticker {
            position: fixed;
            bottom: -10px;
            right: 10px;
            width: 300px;
            height: 300px;
        }

        .framforaContainer {
            & > span:nth-child(1) {
                width: 32% * 2;
                label {
                    width: 100%;
                }
            }
            .framforaCheckbox {
                margin-left: 15px;
            }
        }

        .signup {
            max-width: 800px;
            div {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-evenly;
                span {
                    width: 32%;
                }
            }
            label {
                width: 200px;
                display: inline-block;
            }
        }

        .framfora {
            max-width: 800px;
        }
    }

    .skamusik {
        .split {
            display: flex;
            .tavlor {
                h2 {
                    margin-bottom: 15px;
                }
                width: 30%;
                text-align: left;
                font-size: 1.2rem;
                line-height: 2;

                img {
                    max-width: 350px;
                    display: inline-block;
                    margin-top: 10px;
                }
            }
        }

        .videoplayer {
            .video {
                max-width: 1120px;
                height: 630px;
            }
        }
    }

    .hitta {
        .map {
            max-width: 80%;
            overflow: hidden;
            a, svg {
                display: block;
                width: 105%;
            }
        }
    }

    .hitta, .anmalan, .presenter, .skamusik {
        .rubrik, .navlinks {
            max-width: 445px;
        }

        .navlinks {
            display: flex;
            margin: 10px auto;
            justify-content: space-between;
            a {
                display: inline-block;
                width: 35%;
                margin: 0;
                padding: 3px;
                font-size: 1.3rem;
            }
        }
    }

    .presenter {
        .desc {
            max-width: 800px;
        }
    }

} // end media query
